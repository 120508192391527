import axios, { AxiosRequestConfig } from 'axios';
import * as Sentry from '@sentry/gatsby';
import { generateParams } from '@services/api/helpers';
import { config } from '../../../config';

const windowGutsbyHack = typeof window !== 'undefined' && window;
// Base URL
const baseURL = config.baseHttpUrl;
// Project service
const projectService = config.projectServiceUrl;
// Databank service
const dataBankService = config.dataBankServiceUrl;
// User service
const userService = config.userServiceUrl;
// Notification service
const notificationService = config.notificationServiceUrl;
// Fleet service
const fleetService = config.fleetServiceUrl;
// Connect service
const connectService = config.connectServiceUrl;

export const getToken = () => {
  const token = windowGutsbyHack && windowGutsbyHack.localStorage.getItem('token');
  try {
    if (token) {
      const myToken = JSON.parse(token).accessToken?.token;
      if (myToken) {
        return myToken;
      }
    }

    return '';
  } catch (error: any) {
    return '';
  }
};
// notice - there no longer "base" stuff (ex. no userBase anymore). Pay attention when copy-pasting from old requests
export const Urls = {
  base: baseURL,
  project: projectService,
  dataBank: dataBankService,
  user: userService,
  notification: notificationService,
  fleet: fleetService,
  connect: connectService
};

type ExtraParams = {
  headers?: { [key in string]: string };
  axiosParams?: { [key in string]: string };
};

export const withAuth = (
  $params?: AxiosRequestConfig & ExtraParams & ReturnType<typeof generateParams>
): AxiosRequestConfig => {
  const { headers, axiosParams, ...params } = $params || {};

  return {
    params,
    ...axiosParams,
    headers: {
      Authorization: `Bearer ${getToken()}`,
      'x-source': 'Platform',
      ...headers
    }
  };
};

axios.interceptors.request.use((config) => {
  const companyIdFromUrl = window.location.pathname.match(/^\/(\d+)\/.*/)?.[1];

  if (companyIdFromUrl) {
    // eslint-disable-next-line no-param-reassign
    config.headers['Company-Id'] = companyIdFromUrl;
  }

  return config;
});

axios.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    if (!error.response) {
      try {
        // eslint-disable-next-line no-console
        console.log('Error with no response', error);
        Sentry.captureException(error);
        // eslint-disable-next-line no-console
        console.log('json-stringify', JSON.stringify(error));
      } catch (error) {
        // eslint-disable-next-line no-console
        console.log('catch internal', error);
      }
    }
    if (error.response?.status === 401) {
      if (windowGutsbyHack.location.href !== '/authentication') {
        windowGutsbyHack.location.href = '/authentication';

        return Promise.reject(error);
      }
    }

    return Promise.reject(error);
  }
);

export default axios;
