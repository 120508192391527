import request from 'graphql-request';
import { RequestDocument } from 'graphql-request/dist/types';
import { isArray, isBoolean, isNumber } from 'lodash';
import { getToken, Urls } from './axios';
import { RequestCacheType } from '../../../enums/request.enum';

export const PgColumnIdHeader = 'pg-column-id';

export const postGraphql = async <T>(
  query: RequestDocument,
  variables?: object,
  headers?: object,
  { useReplica = false, cache }: { useReplica?: boolean; cache?: RequestCacheType } = {}
) => {
  const companyIdFromUrl = window.location.pathname.match(/^\/(\d+)\/.*/)?.[1];
  const queryParams = new URLSearchParams({
    ...(cache ? { cache } : {})
  }).toString();

  return request<T>(
    `${useReplica ? `${Urls.project}/graphql-read` : `${Urls.project}/graphql`}${queryParams ? `?${queryParams}` : ''}`,
    query,
    variables,
    {
      Authorization: `Bearer ${getToken()}`,
      'x-company-id': companyIdFromUrl ?? undefined,
      ...(headers || {})
    }
  ).catch((error) => {
    if (error.response?.status === 401) {
      if (window.location.href !== '/authentication') {
        window.location.href = '/authentication';
      }
    }
    throw error;
  });
};

export const postSeriesGraphql = async <T>(query: RequestDocument, variables?: object) => {
  return request<T>(`${Urls.fleet}/graphql`, query, variables, {
    Authorization: `Bearer ${getToken()}`
  }).catch((error) => {
    if (error.response?.status === 401) {
      if (window.location.href !== '/authentication') {
        window.location.href = '/authentication';
      }
    }
    throw error;
  });
};

/**
 * See {@link https://spec.graphql.org/June2018/#sec-String-Value}
 */
const escapeGraphqlString = (str: string) =>
  str
    .replace(/\r\n/g, '\\r\\n')
    .replace(/\n/g, '\\n')
    .replace(/\r/g, '\\r')
    .replace(/"/g, '\\"')
    .replace(/\\/g, '\\\\');

export const literal = (value: number | string | boolean | Array<any>): string =>
  (isArray(value) && `[${value.map(literal).join(',')}]`) ||
  ((isNumber(value) || isBoolean(value)) && `${value}`) ||
  `"${escapeGraphqlString(value as string)}"`;

export const literalEnum = (value: string | Array<string>): string =>
  (isArray(value) && `[${value.map(literalEnum).join(',')}]`) || `${escapeGraphqlString(value as string)}`;

/**
 * Concatenates queries formatted like
 * ```
 * {
 *   alias_1: type(args...) {...}
 * },
 * {
 *   alias_2: type(args...) {...}
 * }
 * ```
 * into plain sequence of queries:
 * ```
 * alias_1: type(args) {...}
 *
 * alias_2: type(args) {...}
 * ```
 *
 * Use it to get graphql highlight and completion inside queries concatented with javascript inside string literal:
 * ```
 * gql`{
 *   rootQuery: (args) {
 *     ${mergeQueries(someGroups.map((group) => gql`{
 *       # you will have code highlight/completion here because of the wrapping braces, but it makes root query invalid
 *       alias_i: type(args) {...}
 *     }`))}
 *   }
 * }`
 * ```
 */
export const mergeQueries = (queries: string[]): string =>
  queries
    .map((query) => {
      const [, inner] = /{\n*(.*)\n*}/gs.exec(query)!;

      return inner;
    })
    .join('\n');
